<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            width="440"
            :min-width="200"
            :max-width="440"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 18em !important;">
                <!-- <img src="/static/images/delete_pop_icon.svg" style="height: 10rem !important" /> -->
                <div>
                    <!-- <div class="mb-2 fw-600" style="color:#f30d0d;font-size: 16px;">Go Back</div> -->
                    <div>
                        <img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br>
                        <span class="fw-600 fs-16">Unsaved Branch Data would be lost, Do you want to proceed?</span>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">        
                    <button
                        type="button"
                        class="btn btn-outline-secondarys fw-600"
                        style="box-shadow: none;min-width: 3rem;width: 90px !important;"
                        @click="hideModal()" 
                    >No</button>
                    <button
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 3rem;"
                        @click="goBack"
                    >Yes</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

export default ({
    props:['modal_name'],
    data(){
        return{

        }
    },
    methods:{
        hideModal(){
            this.$modal.hide(this.modal_name);
            this.$emit('hideModal');
        },
        goBack() {
            this.$modal.hide(this.modal_name);
            this.$emit('goBack');
        }
    }
})

</script>

<style scoped>
    .btn-primary-outline{
        color: #00448b;
		border-radius: 5px;
		padding-left: 35px;
		padding-right: 35px;
		text-transform: none;
		font-weight: 600;
		line-height: 30px;
		box-shadow: none;
		border-color: 2px solid #00448b;
		box-shadow: none !important;
		font-weight: 500;
    }
    .btn-outline-secondarys {
        color: #fff;
        border: 1px solid #e82828 !important;
        background-color: #e82828;
        width: 120px;
        border-radius: 5px;
    }
</style>
