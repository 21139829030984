<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div>
        <div class="d-flex flex-row ml-2 flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div>
                    <span class="pointer">List View</span>
                    <span v-if="edit_enable" class="pointer">Edit Outlet</span>
                    <span v-else class="pointer">View Outlet</span>
                </div>
            </div>
            <div v-if="!edit_enable" class="justify-content-end position-relative d-flex">
                <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="btn btn-icon pointer ml-2 mr-2 text-capitalize" @click="enableEdit" style="padding: 3px 8px !important;background: #00448b;border-radius: 3px !important;">
                    <i class="icon icon-pencil text-secondary pointer fs-16" style="color: #fff !important;"></i>
                    <span class="mx-1" style="color: #fff;font-size: 14px;font-weight: 600;">Edit</span>
                </button>
            </div>
        </div>
        <div class="tabs-content-secondblock bg-white mx-2">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="pt-4 pl-4 mr-2">
                            <span class="fs-14 fw-600" style="color: #00448b;">{{edit_user_data.is_outlet}}</span>
                        </div>
                        <div class="pt-4 pl-4 mr-2">
                            <img class="pointer" width="25" @click="goBack()" src="/static/images/back_page_arrow.svg"> <span class="fs-14 ml-1">Back</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="infoHeader pb-4 mt-3 pt-3">
                            <div class="create-company-header d-flex align-items-center justify-content-between">
                                <span class="fs-14 text-white">Outlets Details</span>
                                <span class="pointer" @click="expandTabs('outlet_info')">
                                    <i class="text-white" :class="{'icon-chevron-double-up':outlet_info_expand, 'icon-chevron-double-down':!outlet_info_expand}" style="font-size: 20px;"></i>
                                </span>
                            </div>
                            <div v-if="outlet_info_expand" style="padding: 0 15px;">
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Outlet Name</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_outlet_name" >
                                                <div class="pointer" @click="resetValue('outlet_name')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('outlet_name')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                            <input name='outlet_name' maxlength="30" class="input-field-underline" @focus="editUser('outlet_name')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.outlet_name">
                                            <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                <span class="input-field-length">{{ edit_user_data.outlet_name.length }}/30</span>
                                            </div>
                                            <!-- </el-tooltip> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Outlet Type</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_outlet_type" >
                                                <div class="pointer" @click="resetValue('outlet_type')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('outlet_type')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                            <input name='outlet_type' maxlength="30" class="input-field-underline" @focus="editUser('outlet_type')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.outlet_type">
                                            <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                <span class="input-field-length">{{ edit_user_data.outlet_type.length }}/30</span>
                                            </div>
                                            <!-- </el-tooltip> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">
                                            Date of Incorporation
                                        </div>
                                        <div class="w-100" >
                                            <div style="position: relative; width: 230px;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" v-if="edit_date_of_incorporation" >
                                                    <div class="pointer" @click="resetValue('date_of_incorporation')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('date_of_incorporation')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <date-picker :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant' || !edit_enable" class="profile-date-picker calendar-edit-per input-field-underline pt-0 pb-0 pr-0" @focus="editUser('date_of_incorporation')" v-model="edit_user_data.date_of_incorporation" value-type="format" lang="en" :popup-style="{ top: '100%', left: 0}" :append-to-body="false" 
                                                :clearable="false"
                                                :editable="false"
                                                :not-after="new Date()" type="date" format="DD/MM/YYYY" style="width:100%;padding-left: 0px !important;padding-bottom: 0px !important;"></date-picker>
                                                <!-- </el-tooltip> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Number of Employees</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_no_of_employees" >
                                                <div class="pointer" @click="resetValue('no_of_employees')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('no_of_employees')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                            <input name='no_of_employees' maxlength="8" class="input-field-underline" v-validate="'numeric'" @focus="editUser('no_of_employees')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model.number="edit_user_data.no_of_employees">
                                            <!-- </el-tooltip> -->
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('no_of_employees')">
                                            {{errors.first('no_of_employees')}}
                                        </span>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Time Zone</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_time_zone" >
                                                <div class="pointer" @click="resetValue('time_zone')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('time_zone')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <div v-if="edit_enable" class="mr-12" style="width:100%">
                                                <multiselect class="diginew-multiselect diginew-multiselect-underline" name="time_zone" v-validate="'required'" label="label" :show-labels="false" :searchable="true" :options="time_zone_options" v-model="edit_user_data.time_zone" placeholder="Select Time Zone">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                                    <input v-else name='time_zone' class="input-field-underline" @focus="editUser('time_zone')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.time_zone.label">
                                                <!-- <div class="input-field-underline" @click="editUser('time_zone')">
                                                    {{edit_user_data.time_zone.label}}
                                                </div> -->
                                            <!-- </el-tooltip> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Currency</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_currency" >
                                                <div class="pointer" @click="resetValue('currency')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('currency')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <div v-if="edit_enable" class="mr-12" style="width: 100%;">
                                                <multiselect class="diginew-multiselect diginew-multiselect-underline" label="label" v-validate="'required'" :show-labels="false" name="currency" :searchable="true" :options="currency_options" v-model="edit_user_data.currency" placeholder="Select Currency">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                                    <input v-else name='currency' class="input-field-underline" @focus="editUser('currency')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.currency.label">
                                                <!-- <div class="input-field-underline" @click="editUser('currency')">
                                                    {{edit_user_data.currency.label}}
                                                </div> -->
                                            <!-- </el-tooltip> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Tax ID</div>
                                        <div class="w-100" style="position: relative;">
                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_tax_id" >
                                                <div class="pointer" @click="resetValue('tax_id')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('tax_id')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                    content="Click to edit"
                                                    placement="top"> -->
                                            <input name='tax_id' maxlength="30" class="input-field-underline" @focus="editUser('tax_id')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.tax_id">
                                            <!-- </el-tooltip> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="create-company-header d-flex align-items-center justify-content-between mt-4">
                                <span class="fs-14 text-white">Communication Information</span>
                                <span class="pointer" @click="expandTabs('communication_info')">
                                    <i class="text-white" :class="{'icon-chevron-double-up':communication_info_expand, 'icon-chevron-double-down':!communication_info_expand}" style="font-size: 20px;"></i>
                                </span>
                            </div>
                            <div v-if="communication_info_expand" style="padding: 0 15px;">
                                <div class="row">
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch E-mail</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_email" >
                                                    <div class="pointer" @click="resetValue('branch_email')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('branch_email')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='branch_email' maxlength="100" v-validate="'email'" class="input-field-underline" @focus="editUser('branch_email')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.branch_email">
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center" >
                                                    <span class="validation" v-show="errors.has('branch_email')">
                                                        Enter valid emaill address
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-12" v-for="(alt_email, alt_email_index) in edit_user_data.alternate_email" :key="'alt_email_index'+alt_email_index">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Email  {{alt_email_index+1}}</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_email && (alt_email_index === edit_alternate_email_index)" >
                                                    <div class="pointer" @click="resetValue('alternate_email', alt_email_index)">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('alternate_email')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='alternate_email' maxlength="100" data-vv-as="Alternate Email" v-validate="'email'" class="input-field-underline" @focus="editUser('alternate_email', alt_email_index)" style="padding-top: 6px !important;" :readonly="!edit_enable && (alt_email_index === edit_alternate_email_index)"  v-model="edit_user_data.alternate_email[alt_email_index]">
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center" >
                                                    <span class="validation" v-show="errors.has('alternate_email'+alt_email_index)">
                                                        Enter valid email address
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch Number</div>
                                            <div v-if="edit_enable" style="width: 128px !important;">
                                                <multiselect v-model="edit_user_data.branch_number_type"
                                                    class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                    :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                    :multiple="false" :searchable="true" :internal-search="true"
                                                    :disabled="false"
                                                    :show-labels="false"
                                                    :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                    :max-height="200" :show-no-results="true" :hide-selected="false">
                                                    <template slot="option" slot-scope="{ option }">
                                                        <div class="d-flex flex-row">
                                                            <span class="ml-2">
                                                                <span style="font-size: 12px">{{ option }}</span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_number" >
                                                    <div class="pointer" @click="resetValue('branch_number')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('branch_number')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <div v-if="edit_enable" >
                                                    <vue-tel-input
                                                        name="branch_number"
                                                        :enabledCountryCode="false"
                                                        :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                        class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                        placeholder="Enter your Phone Number"
                                                        :defaultCountry="edit_user_data.branch_number.country_code"
                                                        @onInput="outletsPhone($event)"
                                                        v-validate="{min:10}"
                                                        v-model="edit_user_data.branch_number.phone_number" style="height: 37px;">
                                                    </vue-tel-input>
                                                </div>
                                                <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                        <input v-else name='phone_number' class="input-field-underline" @focus="editUser('branch_number')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.branch_number.phone_number">
                                                    <!-- <div class="input-field-underline" @click="editUser('branch_number')">
                                                        {{edit_user_data.branch_number.phone_number}}
                                                    </div> -->
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center" >
                                                    <span class="validation" v-show="errors.has('branch_number')">
                                                        Enter valid phone number
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="(alternate_phone, alternate_phone_index) in edit_user_data.alternate_phone" :key="alternate_phone_index+'phh'" class="mt-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Phone Number {{alternate_phone_index+1}}</div>
                                            <div v-if="edit_enable" style="width: 128px !important;">
                                                <multiselect v-model="alternate_phone.phone_type"
                                                    class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                    :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                    :multiple="false" :searchable="true" :internal-search="true"
                                                    :disabled="false"
                                                    :show-labels="false"
                                                    :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                    :max-height="200" :show-no-results="true" :hide-selected="false">
                                                    <template slot="option" slot-scope="{ option }">
                                                        <div class="d-flex flex-row">
                                                            <span class="ml-2">
                                                                <span style="font-size: 12px">{{ option }}</span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_phone && (edit_alternate_phone_index == alternate_phone_index)" >
                                                    <div class="pointer" @click="resetValue('alternate_phone', alternate_phone_index)">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('alternate_phone', alternate_phone_index)">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <div v-if="edit_enable && (edit_alternate_phone_index == alternate_phone_index)">
                                                    <vue-tel-input
                                                        name="branch_number"
                                                        :enabledCountryCode="false"
                                                        :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                        class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                        placeholder="Enter your alternate Phone Number"
                                                        @onInput="outletsAltPhone($event, alternate_phone_index)"
                                                        :defaultCountry="alternate_phone.country_code"
                                                        data-vv-as="Alternate Phone"
                                                        v-validate="{min:10}"
                                                        v-model="alternate_phone.phone_number" style="height: 37px;">
                                                    </vue-tel-input>
                                                </div>
                                                <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                        <input v-else name='phone_number' :readonly="!edit_enable" class="input-field-underline" @focus="editUser('alternate_phone', alternate_phone_index)" style="padding-top: 6px !important;"  v-model="alternate_phone.phone_number">
                                                    <!-- <div class="input-field-underline" @click="editUser('alternate_phone', alternate_phone_index)">
                                                        {{alternate_phone.phone_number}}
                                                    </div> -->
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center" >
                                                    <span class="validation" v-show="errors.has('alternate_phone')">
                                                        Enter valid phone number
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Website</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_website" >
                                                    <div class="pointer" @click="resetValue('website')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('website')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='website' v-validate="'url'" maxlength="100" class="input-field-underline" @focus="editUser('website')" style="padding-top: 6px !important;" :readonly="!edit_enable"  v-model="edit_user_data.website">
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center" >
                                                    <span class="validation" v-show="errors.has('website')">
                                                        Enter valid url
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="create-company-header d-flex align-items-center justify-content-between mt-6">
                                <span class="fs-14 text-white">Outlets Address</span>
                                <div class="d-flex align-items-center">
                                    <!-- <div v-if="edit_full_address == true" class="d-flex justify-content-end align-items-center mr-4">
                                        <div class="pointer" @click="resetValue('full_address_add_update')">
                                            <img width="20" style="width: 20px;" src="/static/images/cross_new.svg">
                                        </div>
                                        <div class="ml-2 pointer" @click="updateInfo('full_address_add_update')">
                                            <img width="20" style="width: 20px;" src="/static/images/check_new.svg">
                                        </div>
                                    </div>
                                    <div>
                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                            <button class="btn-icon pointer mr-4" @click="editFullAddress" style="padding:0px!important;">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/edit-white.svg" height="15px;">
                                            </button>
                                        </el-tooltip>
                                    </div> -->
                                    <span class="pointer" @click="expandTabs('outlet_address')">
                                        <i class="text-white" :class="{'icon-chevron-double-up':outlet_address_expand, 'icon-chevron-double-down':!outlet_address_expand}" style="font-size: 20px;"></i>
                                    </span>
                                </div>
                            </div>
                            <div v-if="outlet_address_expand" class="mt-8" style="padding: 0 15px;">
                                <div class="mt-3">
                                    <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Location </div>
                                        <div class="w-100" style="position: relative;">
                                            <div class="d-flex justify-content-between align-items-center mt-1 px-3">
                                                <div class="gMap" style="position:relative;width:100%;">
                                                    <gmap-autocomplete
                                                        :disabled="!edit_enable"
                                                        class="inputFieldNoBorder mt-0"
                                                        id="autocompleteInput"
                                                        name="get_location"
                                                        v-validate="'required'"
                                                        placeholder="Search Location"
                                                        @place_changed="setPlace($event)"
                                                        :value="address.location_vmodel"
                                                        v-model="address.location_vmodel"
                                                        style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                                    ></gmap-autocomplete>
                                                    <img
                                                        src="/static/images/marker_pin_new.svg"
                                                        style="position: absolute;right: 12px;bottom: 10px;"
                                                    />
                                                </div>
                                                <div class="gMap ml-4" style="cursor: pointer;" :class="{'pointer-events-none':!edit_enable}" @click="getLocation()">
                                                    <img src="/static/images/gps_new.svg" style="height: 40px;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Address</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_building_no" >
                                                    <div class="pointer" @click="resetValue('building_no')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('building_no')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='building_no' maxlength="100" class="input-field-underline" @focus="editUser('building_no')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="address.building_no">
                                                <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                    <span class="input-field-length">{{ address.building_no.length }}/100</span>
                                                </div>
                                                <!-- </el-tooltip> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street1" >
                                                    <div class="pointer" @click="resetValue('street1')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('street1')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='street1' maxlength="50" :readonly="!edit_enable" v-validate="'required'" class="input-field-underline" @focus="editUser('street1')" style="padding-top: 6px !important;" v-model="address.street1">
                                                <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                    <span class="input-field-length">{{ address.street1.length }}/50</span>
                                                </div>
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('street1')">
                                                    {{errors.first('street1')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Town</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_town" >
                                                    <div class="pointer" @click="resetValue('town')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('town')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='town' maxlength="100" v-validate="'required'" class="input-field-underline" @focus="editUser('town')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="address.town">
                                                <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                    <span class="input-field-length">{{ address.town.length }}/50</span>
                                                </div>
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('town')">
                                                    {{errors.first('town')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">City</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_city" >
                                                    <div class="pointer" @click="resetValue('city')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('city')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='city' maxlength="100" v-validate="'required'" class="input-field-underline" @focus="editUser('city')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="address.city">
                                                <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                    <span class="input-field-length">{{ address.city.length }}/100</span>
                                                </div>
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('city')">
                                                    {{errors.first('city')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="mt-5 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street 2</div>
                                            <div class="w-100" style="position: relative;"> -->
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street2" >
                                                    <div class="pointer" @click="resetValue('street1')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('street1')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top">
                                                <input name='street2' maxlength="50" v-validate="'required'" class="input-field-underline" @focus="editUser('street2')" style="padding-top: 6px !important;" :readonly="!edit_street2" v-model="address.street2">
                                                </el-tooltip>
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('street2')">
                                                    {{errors.first('street2')}}
                                                </span>
                                            </div>
                                        </div>
                                        
                                    </div> -->
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">State</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_state" >
                                                    <div class="pointer" @click="resetValue('state')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('state')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <div v-if="edit_enable" style="margin-right: 50px !important;width: 100%;">
                                                    <multiselect
                                                        :max-height="200"
                                                        name="state"
                                                        class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                        v-model="address.state"
                                                        @open="updateState(address.country)"
                                                        :options="states"
                                                        v-validate="'required'"
                                                        :disabled="false"
                                                        @close="stateClose(address.state)"
                                                        :searchable="true"
                                                        :close-on-select="true"
                                                        :show-labels="false"
                                                        openDirection="top"
                                                        :loading="isState"
                                                        data-vv-scope="userData"
                                                        :placeholder="address.country ? 'Select State' : 'Select Country to view States'"
                                                    >
                                                        <template slot="noOptions">
                                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                                        </template>
                                                        <template slot="noResult">
                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                        <input name='state' v-if="!edit_enable" maxlength="100" v-validate="'required'" class="input-field-underline" @focus="editUser('state')" style="padding-top: 6px !important;" :readonly="!edit_city" v-model="address.state">    
                                                    <!-- <div @click="editUser('state')" class="input-field-underline">
                                                        {{address.state}}
                                                    </div> -->
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('state')">
                                                    {{errors.first('state')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Zip Code</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_zipcode" >
                                                    <div class="pointer" @click="resetValue('zipcode')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('zipcode')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                <input name='zip_code' maxlength="10" v-validate="'required'" class="input-field-underline" @focus="editUser('zipcode')" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="address.zipcode">
                                                <div v-if="edit_enable" class="text-right" style="position: absolute;right: 0px;">
                                                    <span class="input-field-length">{{ address.zipcode.length }}/10</span>
                                                </div>
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('zip_code')">
                                                    {{errors.first('zip_code')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Country</div>
                                            <div class="w-100" style="position: relative;">
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_country" >
                                                    <div class="pointer" @click="resetValue('country')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('country')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <div v-if="edit_enable" style="margin-right: 50px !important;width:100%">
                                                    <multiselect
                                                        :max-height="200"
                                                        name="country_name"
                                                        class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                        v-model="address.country"
                                                        @input="address.state = '', address.city = '',address.street1 = '',address.town = '',address.zipcode = '',address.location_vmodel = ''"
                                                        :options="countries"
                                                        @close="countryClose(address.country)"
                                                        :disabled="false"
                                                        openDirection="top"
                                                        v-validate="'required'"
                                                        :searchable="true"
                                                        :close-on-select="true"
                                                        :show-labels="false"
                                                        data-vv-scope="Country"
                                                        placeholder="Select Country"
                                                    >
                                                        <template slot="noOptions">
                                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                                        </template>
                                                        <template slot="noResult">
                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        content="Click to edit"
                                                        placement="top"> -->
                                                        <input name='country' v-if="!edit_enable" maxlength="100" v-validate="'required'" class="input-field-underline" @focus="editUser('country')" style="padding-top: 6px !important;" :readonly="!edit_city" v-model="address.country">  
                                                    <!-- <div @click="editUser('country')" class="input-field-underline">
                                                        {{address.country}}
                                                    </div> -->
                                                <!-- </el-tooltip> -->
                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('country_name')">
                                                    {{errors.first('country_name')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                        <button class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                        <button class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" @click="updateValue()" style="width: 120px;border-radius: 5px;">Save
                            <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import {
        EventBus
    } from "../../eventBus/nav-bar-event.js";
    import moment from "moment";
    import { SweetModal } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import ViewCompany from '../ViewCompany';
    import DatePicker from 'vue2-datepicker'
    import countries from "../../json/countries.json";
    import timezone from "../../json/timezone.json";
    import currencies from "../../json/currency.json";
    import Element from 'element-ui'
    import axios from 'axios';
    import companies from '../../mixins/companies';
import globals from '../../globals';
    export default {
        props: [],
        components:{
            DatePicker,
            Element,
            HalfCircleSpinner,
            SweetModal, ViewCompany
        },
        data() {
            return {
                upload_in_progress: false,
                save_api_call_in_progress: false,
                edit_enable: false,
                warning_msg: '',
                success_msg: '',
                edit_user_data: {
                    outlet_name: '',
                    no_of_employees: '',
                    outlet_type: '',
                    date_of_incorporation: '',
                    time_zone: '',
                    branch_email: '',
                    alternate_email: [],
                    branch_number: {},
                    alternate_phone: [],
                    website: '',
                    branch_number_type: '',
                    alternate_phone_number_type: '',
                    tax_id: '',
                    currency: ''
                },
                currency_options: currencies,
                countries: countries,
                address: {
                    building_no: '',
                    street1: '',
                    street2: '',
                    city: '',
                    town: '',
                    state: '',
                    country: '',
                    zipcode: '',
                    location_vmodel: ""
                },
                isState: false,
                states: [],
                edit_outlet_name: false,
                edit_industry_type: false,
                edit_outlet_type: false,
                edit_date_of_incorporation: false,
                edit_building_no: false,
                edit_no_of_employees: false,
                edit_time_zone: false,
                edit_tax_id: false,
                edit_currency: false,
                edit_street1: false,
                edit_street2: false,
                edit_city: false,
                edit_town: false,
                edit_state: false,
                edit_country: false,
                edit_zipcode: false,
                edit_city: false,
                edit_branch_email: false,
                edit_alternate_email: false,
                edit_branch_number: false,
                edit_alternate_phone: false,
                edit_website: false,
                ajax_call_in_progress: false,
                industry_type_options: [],
                no_of_employees_options: [],
                edit_full_address: false,
                outlet_info_expand: true,
                outlet_address_expand: true,
                communication_info_expand: true,
                outlet_data: [],
                time_zone_options: timezone,
                edit_alternate_phone_index: 0,
                edit_alternate_email_index: 0,
                stringified_response: "",
            };
        },
        mixins: [companies],
        beforeRouteLeave (to, from, next) {
            if (this.fields_not_changed == false) {
                    let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                    let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        created() {
            EventBus.$on("enableEdit", this.enableEdit);
            const dict = {
                custom: {
                    no_of_employees:{
                        numeric:() => "Please enter a valid number",
                    },
                    country_name:{
                        required:() => "Country is required",
                    },
                    street1:{
                        required:() => "Street1 is required",
                    },
                    township:{
                        required:() => "Township is required",
                    },
                    state:{
                        required:() => "State is required",
                    },
                    city:{
                        required:() => "City is required",
                    },
                    zip_code:{
                        required:() => "Zip Code is required",
                        numeric:() => "Please enter a valid zip code",
                    }
                }
            }
            this.$validator.localize('en', dict)
        },
        destroyed() {
            EventBus.$off("enableEdit", this.enableEdit);
        },
        methods: {
            enableEdit() {
                this.edit_enable = true;
            },
            cancel(index){
                let outlet_data = JSON.parse(localStorage.getItem('outlet_info'));
                    this.edit_full_address = false;
                    this.edit_country = false;
                    this.edit_state = false;
                    this.edit_city = false;
                    this.edit_building_no = false;
                    this.edit_town = false;
                    this.edit_street1 = false;
                    this.edit_street2 = false;
                    this.edit_zipcode = false;
                    this.address.country = outlet_data == undefined || null ? '' : outlet_data.country
                    this.address.state = outlet_data == undefined || null ? '' : outlet_data.state
                    this.address.building_no = outlet_data == undefined || null ? '' : outlet_data.building_no
                    this.address.city = outlet_data == undefined || null ? '' : outlet_data.city
                    this.address.town = outlet_data == undefined || null ? '' : outlet_data.township
                    this.address.street1 = outlet_data == undefined || null ? '' : outlet_data.street1
                    this.address.street2 = outlet_data == undefined || null ? '' : outlet_data.street2;
                    this.address.zipcode = outlet_data == undefined || null ? '' : outlet_data.zip_code
                    this.$forceUpdate();
                
                    this.edit_outlet_name = false;
                    this.edit_user_data.outlet_name = outlet_data.outlet_name == undefined || null ? '' : outlet_data.outlet_name
                
                    this.edit_outlet_type = false;
                    this.edit_user_data.outlet_type = outlet_data.outlet_type == undefined || null ? '' : outlet_data.outlet_type
                
                    this.edit_date_of_incorporation = false;
                    this.edit_user_data.date_of_incorporation = outlet_data.date_of_incorporation == undefined || null ? '' : outlet_data.date_of_incorporation.split('-')[2]+'/'+outlet_data.date_of_incorporation.split('-')[1]+'/'+outlet_data.date_of_incorporation.split('-')[0];
                
                    this.edit_no_of_employees = false;
                    this.edit_user_data.no_of_employees = outlet_data.no_of_employees == undefined || null ? 0 : outlet_data.no_of_employees;
                
                    this.edit_building_no = false;
                    this.address.building_no = outlet_data.building_no == undefined || null ? '' : outlet_data.building_no;
                
                    this.edit_street1 = false;
                    this.address.street1 = outlet_data.street1 == undefined || null ? '' :outlet_data.street1;
                
                    this.edit_street2 = false;
                    this.address.street2 = outlet_data.street2 == undefined || null ? '' :outlet_data.street2;
                
                    this.edit_city = false;
                    this.address.city = outlet_data.city == undefined || null ? '' :outlet_data.city;
                
                    this.edit_branch_email = false;
                    this.edit_user_data.branch_email = outlet_data.email == undefined || null ? '' :outlet_data.email;
                
                    this.edit_branch_email = false;
                    this.edit_user_data.alternate_email[index] = outlet_data.alternate_email[index] == undefined || null ? '' : outlet_data.alternate_email[index];
                
                    this.edit_branch_number = false;
                    this.edit_user_data.branch_number = outlet_data.primary_phone == undefined || null ? '' :outlet_data.primary_phone;
                
                    this.edit_alternate_phone = false;
                    // this.edit_user_data.alternate_phone[index].phone_number = outlet_data.alternate_phone_numbers[index] == undefined || null ? '' :outlet_data.alternate_phone_numbers[index].phone_number;
                
                    this.edit_website = false;
                    this.edit_user_data.website = outlet_data.website == undefined || null ? '' :outlet_data.website;
                
                    this.edit_state = false;
                    this.address.state = outlet_data.state == undefined || null ? '' :outlet_data.state;
                
                    this.edit_town = false;
                    this.address.town = outlet_data.township == undefined || null ? '' :outlet_data.township;
                
                    this.edit_country = false;
                    this.address.country_name = outlet_data.country == undefined || null ? '' :outlet_data.country;
                
                    this.edit_zipcode = false;
                    this.address.zipcode = outlet_data.zip_code == undefined || null ? '' :outlet_data.zip_code;
                
                    this.edit_time_zone = false;
                    this.edit_user_data.time_zone = outlet_data.time_zone == undefined || null ? '' :outlet_data.time_zone;
                
                    this.edit_currency = false;
                    this.edit_user_data.currency = outlet_data.currency == undefined || null ? '' :outlet_data.currency;
                
                    this.edit_tax_id = false;
                    this.edit_user_data.tax_id = outlet_data.tax_id == undefined || null ? '' :outlet_data.tax_id;
                this.edit_enable = false
                this.$forceUpdate();
                
            },
            async updateValue(index) {
                try {
                    let params = {
                    };
                    params.outlet_name = this.edit_user_data.outlet_name;
                
                    params.outlet_type = this.edit_user_data.outlet_type;
                
                    params.date_of_incorporation = this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : "";
                
                    params.no_of_employees = this.edit_user_data.no_of_employees;
                
                    params.tax_id = this.edit_user_data.tax_id;
                
                    params.building_no = this.address.building_no;
                
                    params.street1 = this.address.street1;
                
                    params.street2 = this.address.street2;
                
                    params.city = this.address.city;
                
                    params.email = this.edit_user_data.branch_email;
                    params.primary_number = {
                        id: this.edit_user_data.branch_number.id,
                        phone_type: this.edit_user_data.branch_number_type,
                        phone_number: this.edit_user_data.branch_number.phone_number,
                        country_code: this.edit_user_data.branch_number.country_code,
                        dial_code: this.edit_user_data.branch_number.dial_code,
                    }
                    var phoneNumberDetails = []
                    this.edit_user_data.alternate_phone.forEach((el, index)=>{
                        phoneNumberDetails = {
                                id: this.edit_user_data.alternate_phone[index].id,
                                phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                                phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                                country_code: this.edit_user_data.alternate_phone[index].country_code,
                                dial_code: this.edit_user_data.alternate_phone[index].phone_number.split(" ")[0],
                            }
                            this.edit_user_data.alternate_phone[index] = phoneNumberDetails
                        })
                    params.alternate_phone_numbers = this.edit_user_data.alternate_phone
                    params.alternate_email = this.edit_user_data.alternate_email;
                
                    params.website = this.edit_user_data.website;
                
                    params.state = this.address.state;
                
                    params.township = this.address.town;
                
                    params.country = this.address.country;
                
                    params.currency = this.edit_user_data.currency;
                
                    params.zip_code = this.address.zipcode;
                
                    params.time_zone = this.edit_user_data.time_zone.label;
                    this.save_api_call_in_progress = true
                    let response = await this.updateOutletsById(params, this.$route.params.id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getOutletDataById();
                        }, 2000);
                        this.save_api_call_in_progress = false
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            moment(date) {
                return moment(date);
            },
            goBack() {
                window.history.back();
            },
            checkNumberValidation(data) {
                console.log(data.target.value)
                let regex = /^[0-9]*$/;
                if(!regex.test(data.target.value)) {
                    this.warning_msg = 'Number of employees must be numeric.';
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            },
            expandTabs(type) {
                if(type == 'outlet_info') {
                    this.outlet_info_expand = !this.outlet_info_expand;
                } else if(type == 'communication_info') {
                    this.communication_info_expand = !this.communication_info_expand;
                } else if(type == 'outlet_address') {
                    this.outlet_address_expand = !this.outlet_address_expand;
                }
            },
            outletsPhone({number,isValid,country}) {
                this.edit_user_data.branch_number.phone_number = number.national;
                this.edit_user_data.branch_number.dial_code = country.dialCode;
                this.edit_user_data.branch_number.country_code = country.iso2;
            },
            outletsAltPhone({number,isValid,country}, phoneindex) {
                this.edit_user_data.alternate_phone[phoneindex].phone_number = number.national;
                this.edit_user_data.alternate_phone[phoneindex].dial_code = country.dialCode;
                this.edit_user_data.alternate_phone[phoneindex].country_code = country.iso2;
            },
            editFullAddress() {
                this.edit_full_address = true;
                this.edit_country = false;
                this.edit_state = false;
                this.edit_building_no = false;
                this.edit_city = false;
                this.edit_town = false;
                this.edit_street1 = false;
                this.edit_street2 = false;
                this.edit_zipcode = false;
            },
            editUser(obj, index){
                if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                    if(obj == 'outlet_name'){
                        this.edit_outlet_name = true;
                        this.edit_time_zone = false;
                        this.edit_no_of_employees = false;
                        this.edit_outlet_type = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_state = false;
                        this.edit_currency = false;
                        this.edit_town = false;
                        this.edit_tax_id = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'outlet_type'){
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = true;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'no_of_employees'){
                        this.edit_outlet_name = false;
                        this.edit_no_of_employees = true;
                        this.edit_outlet_type = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'date_of_incorporation'){
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = true;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'building_no') {
                        this.edit_building_no = true;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'street1') {
                        this.edit_street1 = true;
                        this.edit_building_no = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_currency = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'street2') {
                        this.edit_street2 = true;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_currency = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'city') {
                        this.edit_city = true;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_currency = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'time_zone'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_time_zone = true;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_tax_id = false;
                        this.edit_currency = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'currency'){
                        this.edit_currency = true;
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_tax_id = false;
                        this.edit_time_zone = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'tax_id'){
                        this.edit_tax_id = true;
                        this.edit_currency = false;
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_time_zone = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'branch_email'){
                        this.edit_branch_email = true;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_tax_id = false;
                        this.edit_currency = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'alternate_email'){
                        this.edit_alternate_email_index = index;
                        this.edit_alternate_email = true;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_street1 = false;
                        this.edit_time_zone = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'branch_number'){
                        this.edit_branch_number = true;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'alternate_phone'){
                        this.edit_alternate_phone_index = index;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = true;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_time_zone = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_tax_id = false;
                        this.edit_currency = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'website'){
                        this.edit_website = true;
                        this.edit_time_zone = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'state'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_state = true;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'town'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = false;
                        this.edit_time_zone = false;
                        this.edit_town = true;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'country'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_town = false;
                        this.edit_time_zone = false;
                        this.edit_country = true;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'zipcode'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_outlet_name = false;
                        this.edit_outlet_type = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_currency = false;
                        this.edit_tax_id = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_time_zone = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = true;
                        this.edit_street2 = false;
                    }
                }
            },
            resetValue(obj, index){
                let outlet_data = JSON.parse(localStorage.getItem('outlet_info'));
                console.log(outlet_data)
                if(obj == "full_address_add_update") {
                    this.edit_full_address = false;
                    this.edit_country = false;
                    this.edit_state = false;
                    this.edit_city = false;
                    this.edit_building_no = false;
                    this.edit_town = false;
                    this.edit_street1 = false;
                    this.edit_street2 = false;
                    this.edit_zipcode = false;
                    this.address.country = outlet_data == undefined || null ? '' : outlet_data.country
                    this.address.state = outlet_data == undefined || null ? '' : outlet_data.state
                    this.address.building_no = outlet_data == undefined || null ? '' : outlet_data.building_no
                    this.address.city = outlet_data == undefined || null ? '' : outlet_data.city
                    this.address.town = outlet_data == undefined || null ? '' : outlet_data.township
                    this.address.street1 = outlet_data == undefined || null ? '' : outlet_data.street1
                    this.address.street2 = outlet_data == undefined || null ? '' : outlet_data.street2;
                    this.address.zipcode = outlet_data == undefined || null ? '' : outlet_data.zip_code
                    this.$forceUpdate();
                } else {
                    if(obj == 'outlet_name'){
                        this.edit_outlet_name = false;
                        this.edit_user_data.outlet_name = outlet_data.outlet_name == undefined || null ? '' : outlet_data.outlet_name
                    }
                    else if(obj == 'outlet_type'){
                        this.edit_outlet_type = false;
                        this.edit_user_data.outlet_type = outlet_data.outlet_type == undefined || null ? '' : outlet_data.outlet_type
                    }
                    else if(obj == 'date_of_incorporation'){
                        this.edit_date_of_incorporation = false;
                        this.edit_user_data.date_of_incorporation = outlet_data.date_of_incorporation == undefined || null ? '' : outlet_data.date_of_incorporation.split('-')[2]+'/'+outlet_data.date_of_incorporation.split('-')[1]+'/'+outlet_data.date_of_incorporation.split('-')[0];
                    }
                    else if (obj == 'no_of_employees') {
                        this.edit_no_of_employees = false;
                        this.edit_user_data.no_of_employees = outlet_data.no_of_employees == undefined || null ? 0 : outlet_data.no_of_employees;
                    }
                    else if (obj == 'building_no') {
                        this.edit_building_no = false;
                        this.address.building_no = outlet_data.building_no == undefined || null ? '' : outlet_data.building_no;
                    }
                    else if(obj == 'street1') {
                        this.edit_street1 = false;
                        this.address.street1 = outlet_data.street1 == undefined || null ? '' :outlet_data.street1;
                    }
                    else if(obj == 'street2') {
                        this.edit_street2 = false;
                        this.address.street2 = outlet_data.street2 == undefined || null ? '' :outlet_data.street2;
                    }
                    else if (obj == 'city') {
                        this.edit_city = false;
                        this.address.city = outlet_data.city == undefined || null ? '' :outlet_data.city;
                    }
                    else if(obj == 'branch_email'){
                        this.edit_branch_email = false;
                        this.edit_user_data.branch_email = outlet_data.email == undefined || null ? '' :outlet_data.email;
                    }
                    else if(obj == 'alternate_email'){
                        this.edit_branch_email = false;
                        this.edit_user_data.alternate_email[index] = outlet_data.alternate_email[index] == undefined || null ? '' : outlet_data.alternate_email[index];
                    }
                    else if(obj == 'branch_number'){
                        this.edit_branch_number = false;
                        this.edit_user_data.branch_number = outlet_data.primary_phone == undefined || null ? '' :outlet_data.primary_phone;
                    }
                    else if(obj == 'alternate_phone'){
                        this.edit_alternate_phone = false;
                        this.edit_user_data.alternate_phone[index].phone_number = outlet_data.alternate_phone_numbers[index] == undefined || null ? '' :outlet_data.alternate_phone_numbers[index].phone_number;
                    }
                    else if(obj == 'website'){
                        this.edit_website = false;
                        this.edit_user_data.website = outlet_data.website == undefined || null ? '' :outlet_data.website;
                    }
                    else if(obj == 'state'){
                        this.edit_state = false;
                        this.address.state = outlet_data.state == undefined || null ? '' :outlet_data.state;
                    }
                    else if(obj == 'town'){
                        this.edit_town = false;
                        this.address.town = outlet_data.township == undefined || null ? '' :outlet_data.township;
                    }
                    else if(obj == 'country'){
                        this.edit_country = false;
                        this.address.country_name = outlet_data.country == undefined || null ? '' :outlet_data.country;
                    }
                    else if(obj == 'zipcode'){
                        this.edit_zipcode = false;
                        this.address.zipcode = outlet_data.zip_code == undefined || null ? '' :outlet_data.zip_code;
                    }
                    else if(obj == 'time_zone'){
                        this.edit_time_zone = false;
                        this.edit_user_data.time_zone = outlet_data.time_zone == undefined || null ? '' : {
                            label: outlet_data.time_zone,
                            value: ''
                        };
                    }
                    else if(obj == 'currency'){
                        this.edit_currency = false;
                        this.edit_user_data.currency = outlet_data.currency == undefined || null ? '' :outlet_data.currency;
                    }
                    else if(obj == 'tax_id'){
                        this.edit_tax_id = false;
                        this.edit_user_data.tax_id = outlet_data.tax_id == undefined || null ? '' :outlet_data.tax_id;
                    }
                    this.$forceUpdate();
                }
            },
            countryClose(data) {
                this.address.country = data;
                this.$forceUpdate();
            },
            stateClose(data) {
                this.address.state = data;
                this.$forceUpdate();
            },
            updateState(country, index) {
                // this.edit_state = true;
                this.isState = true;
                this.states = [];
                if (country) {
                    let params = {
                        country_required: false,
                        state_required: true,
                        country_name: country,
                    };
                    axios.post(globals.CRM_API +"/location/get_location", params).then(res => {
                    if (res.data.status_id == 1 && "all_states" in res.data.response) {
                        res.data.response.all_states.forEach(x => {
                        this.states.push(x);
                        });
                        this.isState = false
                    }
                    });
                }
            },
            setPlace(place) {
                let currentPlace = place;
                let address = place.formatted_address.split(", ");
                this.address.street1 =
                    address[address.length - 3] || "";
                this.address.town =
                    address[address.length - 4] || "";
                this.address.city = currentPlace.vicinity;
                let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                if (statezip.length == 1) {
                    if (statezip[0].match(/^[0-9]+$/)) {
                    this.address.zipcode = statezip[0];
                    } else {
                    this.address.state = statezip[0];
                    }
                } else {
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                }
                if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                        this.address.country = x;
                    } else {
                        this.address.country = "";
                    }
                    });
                } else {
                    this.address.country = address[address.length - 1];
                }
                // this.address[index].address.latitude = currentPlace.geometry.location.lat() || "";
                // this.address[index].address.longitude = currentPlace.geometry.location.lng() || "";
                this.address.location_vmodel = place.formatted_address;
                this.$forceUpdate();
            },
            getLocation() {
                if (navigator.geolocation) {
                    // timeout at 60000 milliseconds (60 seconds)
                    var options = {
                    enableHighAccuracy: false,
                    timeout: 30000, // milliseconds (30 seconds)
                    maximumAge: 600000
                    }; // milliseconds (10 minutes)
                    navigator.geolocation.getCurrentPosition(
                    this.showLocation,
                    this.errorHandler,
                    options
                    );
                } else {
                    alert("Sorry, browser does not support geolocation!");
                }
            },
            showLocation(position) {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
                let calllatlong = axios.create();
                delete calllatlong.defaults.headers.common['Authorization'];
                calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
                .then(response => {
                    let address = response.data.results[0].formatted_address.split(", ");
                    this.address.street1 =
                    address[address.length - 6] || "";
                    this.address.town =
                    address[address.length - 4] || "";
                    this.address.city = address[address.length - 3] || "";
                    let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                    if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                        if (this.countries.includes(x)) {
                        this.address.country = x;
                        } else {
                        this.address.country = "";
                        }
                    });
                    } else {
                    this.address.country = address[address.length - 1];
                    }
                    this.address.location_vmodel = response.data.results[0].formatted_address;
                    this.$forceUpdate();
                })
                .catch(e => {
                    // this.errors.push(e)
                });
            },
            errorHandler(err) {
                if (err.code == 1) {
                    // alert("Error: Access is denied!");
                } else if (err.code == 2) {
                    // alert("Error: Position is unavailable!");
                }
            },
            async getOutletDataById() {
                this.edit_website = false;
                this.edit_branch_number = false;
                this.edit_alternate_phone = false;
                this.edit_branch_email = false;
                this.edit_outlet_name = false;
                this.edit_outlet_type = false;
                this.edit_no_of_employees = false;
                this.edit_building_no = false;
                this.edit_street1 = false;
                this.edit_street2 = false;
                this.edit_currency = false;
                this.edit_tax_id = false;
                this.edit_city = false;
                this.edit_date_of_incorporation = false;
                this.edit_time_zone = false;
                this.edit_state = false;
                this.edit_town = false;
                this.edit_country = false;
                this.edit_zipcode = false;
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getOutletsById(this.$route.params.id);
                    if(response.status_id == 1) {
                        localStorage.setItem('outlet_info', JSON.stringify(response.response));
                        let outletdata = response.response;
                        this.outlet_data = response.response;
                        this.edit_user_data.outlet_name = outletdata.outlet_name;
                        this.edit_user_data.no_of_employees = outletdata.no_of_employees;
                        this.edit_user_data.outlet_type = outletdata.outlet_type;
                        this.edit_user_data.date_of_incorporation = outletdata.date_of_incorporation ? outletdata.date_of_incorporation.split('-')[2]+'/'+outletdata.date_of_incorporation.split('-')[1]+'/'+outletdata.date_of_incorporation.split('-')[0] : '';
                        this.edit_user_data.time_zone = {
                            label: outletdata.time_zone,
                            value: ''
                        };
                        this.edit_user_data.branch_email = outletdata.email;
                        if(outletdata.alternate_email.length > 0) {
                            this.edit_user_data.alternate_email = outletdata.alternate_email;
                        } else {
                            this.edit_user_data.alternate_email = [];
                        }
                        this.edit_user_data.branch_number = outletdata.primary_phone;
                        if(outletdata.alternate_phone_numbers.length > 0) {
                            this.edit_user_data.alternate_phone = outletdata.alternate_phone_numbers;
                        } else {
                            this.edit_user_data.alternate_phone = [];
                        }
                        this.edit_user_data.website = outletdata.website;
                        this.edit_user_data.branch_number_type = outletdata.primary_phone.phone_type;
                        this.edit_user_data.tax_id = outletdata.tax_id;
                        this.edit_user_data.currency = outletdata.currency;
                        this.address.building_no = outletdata.building_no,
                        this.address.street1 = outletdata.street1,
                        this.address.street2 = outletdata.street2,
                        this.address.city = outletdata.city,
                        this.address.town = outletdata.township,
                        this.address.state = outletdata.state,
                        this.address.country = outletdata.country,
                        this.address.zipcode = outletdata.zip_code,
                        this.address.location_vmodel = outletdata.location
                        this.ajax_call_in_progress = false;
                        this.stringified_response = JSON.stringify(this.create_data)
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async updateInfo(obj, index) {
                try {
                    let params = {
                    };
                    if(obj == "full_address_add_update") {
                        params.country = this.address.country
                        params.state = this.address.state
                        params.building_no = this.address.building_no
                        params.city = this.address.city
                        params.township = this.address.town
                        params.street1 = this.address.street1
                        params.street2 = this.address.street2;
                        params.zip_code = this.address.zipcode
                    } else {
                        if(obj == 'outlet_name'){
                            params.outlet_name = this.edit_user_data.outlet_name;
                        }
                        else if(obj == 'outlet_type'){
                            params.outlet_type = this.edit_user_data.outlet_type;
                        }
                        else if(obj == 'date_of_incorporation'){
                            params.date_of_incorporation = this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : "";
                        }
                        else if (obj == 'no_of_employees') {
                            params.no_of_employees = this.edit_user_data.no_of_employees;
                        }
                        else if (obj == 'tax_id') {
                            params.tax_id = this.edit_user_data.tax_id;
                        }
                        else if (obj == 'building_no') {
                            params.building_no = this.address.building_no;
                        }
                        else if(obj == 'street1') {
                            params.street1 = this.address.street1;
                        }
                        else if(obj == 'street2') {
                            params.street2 = this.address.street2;
                        }
                        else if (obj == 'city') {
                            params.city = this.address.city;
                        }
                        else if(obj == 'branch_email'){
                            params.email = this.edit_user_data.branch_email;
                        }
                        else if(obj == 'branch_number'){
                            params.primary_number = {
                                id: this.edit_user_data.branch_number.id,
                                phone_type: this.edit_user_data.branch_number_type,
                                phone_number: this.edit_user_data.branch_number.phone_number,
                                country_code: this.edit_user_data.branch_number.country_code,
                                dial_code: this.edit_user_data.branch_number.dial_code,
                            }
                        }
                        else if(obj == 'alternate_phone'){
                            params.alternate_phone_numbers = [
                                {
                                    id: this.edit_user_data.alternate_phone[index].id,
                                    phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                                    phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                                    country_code: this.edit_user_data.alternate_phone[index].country_code,
                                    dial_code: this.edit_user_data.alternate_phone[index].phone_number.split(" ")[0],
                                }
                            ]
                        }
                        else if(obj == 'alternate_email'){
                            params.alternate_email = this.edit_user_data.alternate_email;
                        }
                        else if(obj == 'website'){
                            params.website = this.edit_user_data.website;
                        }
                        else if(obj == 'state'){
                            params.state = this.address.state;
                        }
                        else if(obj == 'town'){
                            params.township = this.address.town;
                        }
                        else if(obj == 'country'){
                            params.country = this.address.country;
                        }
                        else if(obj == 'currency'){
                            params.currency = this.edit_user_data.currency;
                        }
                        else if(obj == 'zipcode'){
                            params.zip_code = this.address.zipcode;
                        }
                        else if(obj == 'time_zone'){
                            params.time_zone = this.edit_user_data.time_zone.label;
                        }
                    }
                    let response = await this.updateOutletsById(params, this.$route.params.id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getOutletDataById();
                        }, 2000);
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            }

        },
        mounted() {
            this.getOutletDataById();
        },
        created() {
            const dict = {
                custom: {
                    no_of_employees:{
                        regex:() => "Please enter a valid number",
                    },
                    country_name:{
                        required:() => "Country is required",
                    },
                    street1:{
                        required:() => "Street1 is required",
                    },
                    township:{
                        required:() => "Township is required",
                    },
                    state:{
                        required:() => "State is required",
                    },
                    city:{
                        required:() => "City is required",
                    },
                    zip_code:{
                        required:() => "Zip Code is required",
                        numeric:() => "Please enter a valid zip code",
                    }
                }
            }
            this.$validator.localize('en', dict)
        },
        watch: {
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            create_data() {
                return {
                    "outlet_name": this.edit_user_data.outlet_name,
                    "no_of_employees": this.edit_user_data.no_of_employees,
                    "outlet_type": this.edit_user_data.outlet_type,
                    "date_of_incorporation": this.edit_user_data.date_of_incorporation,
                    "time_zone":  this.edit_user_data.time_zone,
                    "branch_email": this.edit_user_data.branch_email,
                    "alternate_email": this.edit_user_data.alternate_email ,
                    "branch_number": this.edit_user_data.branch_number,
                    "alternate_phone":  this.edit_user_data.alternate_phone,
                    "website": this.edit_user_data.website,
                    "branch_number_type": this.edit_user_data.branch_number_type,
                    "tax_id": this.edit_user_data.tax_id, 
                    "currency": this.edit_user_data.currency,
                    "building_no": this.address.building_no,
                    "street1": this.address.street1,
                    "street2": this.address.street2,
                    "city": this.address.city,
                    "town": this.address.town,
                    "state": this.address.state,
                    "country": this.address.country,
                    "zipcode": this.address.zipcode,
                    "location_vmodel": this.address.location_vmodel
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
        beforeDestroy() {
            localStorage.removeItem('outlet_info');
        },
    };
</script>
<style scoped>
    .create-company-header {
        padding: 9.9px 15px;
        background-color:#00448b;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .validation{
        color: #e82828;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>