import { render, staticRenderFns } from "./GoBackPopup.vue?vue&type=template&id=f56a9f00&scoped=true&"
import script from "./GoBackPopup.vue?vue&type=script&lang=js&"
export * from "./GoBackPopup.vue?vue&type=script&lang=js&"
import style0 from "./GoBackPopup.vue?vue&type=style&index=0&id=f56a9f00&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56a9f00",
  null
  
)

export default component.exports