<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            width="440"
            :min-height="200"
            :min-width="440"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 25em !important;">
                <img src="/static/images/delete_pop_icon.svg" width="103px" height="109px" />
                <div class="mt-5">
                    <!-- <div class="mb-4" style="color:#f30d0d;font-size: 18px;">Delete Business Unit</div> -->
                    <div>
                        <span class="fs-15 fw-600">Are you sure you  want to delete Outlet ?</span>
                    </div>
                    <!-- <div class="text-center">
                        <span class="fs-13">
                            Deleting Business Unit will also delete All:
                        </span>
                        <div class="d-flex align-items-center flex-column mt-2">
                            <ul class="p-0">
                                <li class="fs-13 text-left">Departments</li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">  
                    <button
                        class="btn btn-outline-secondarys"
                        style="width: 80px;border-radius: 5px;"
                        @click="hideConfirm"
                    >No</button>
                    <button
                        type="button"
                        class="btn btn-new-success ml-3"
                        style="width: 80px;border-radius: 5px;"
                        @click="deleteConfirm"
                    >Yes</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name"],
    methods: {
        hideConfirm() {
            this.$modal.hide(this.modal_name);
            this.$emit('hideConfirm');
        },
        deleteConfirm() {
            this.$modal.hide(this.modal_name);
            this.$emit('deleteConfirm');
        }
    }
}
</script>
<style>
     .btn-outline-secondarys {
        color: #fff;
        border: 1px solid #e82828 !important;
        background-color: #e82828;
    }
</style>